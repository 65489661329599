import React, { Fragment, Suspense, useEffect, useMemo } from "react";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import { createFakeThread, type PossibleThreadContent } from "~/utils/thread";
import type { ThreadsLoader } from "~/routes/threads";
import { postThreadMain } from "~/utils/transactions";
import ThreadsEditor from "~/components/threads/ThreadsEditor";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import type { Thread } from "~/utils/leocache";
import { generateFeedSession } from "~/utils/leocache";
import { ThreadView } from "~/components/threads/ThreadView";
import { useAppStore } from "~/store";
import { Spinner } from "~/components/format/Spinner";
import { useThreadsV2 } from "~/hooks/useThreadsV2";
import { useThreads } from "~/hooks/useThreads";
import { useSaveLeoCacheSeen } from "~/hooks/useSaveLeoCacheSeen";
import { SmallAvatar6 } from "~/components/format/SmallAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { loadTranslator } from "~/utils/loadTranslator";

export const meta: MetaFunction = () => [
  { title: "InLeo | Microblogging in Blockchain" },
  { property: "og:title", content: "InLeo | Microblogging in Blockchain" }
];

export const loader: LoaderFunction = async ({ request }) => {
  return redirect("/threads/v2");
  // const activeAccountName = await getActiveAccount(request);

  // if (activeAccountName) {
  //   const settings = await getInfraSettings(activeAccountName);

  //   const path = request.url.split(request.headers.get("host") as string)[1];

  //   if (
  //     settings?.settings &&
  //     path !== `/threads/${settings.settings.default_feed}`
  //   ) {
  //     console.error("USER GOT REDIRECTED");
  //     return redirect(`/threads/${settings.settings.default_feed}`);
  //   }
  // }

  // const threadIndices = activeAccountName
  //   ? await leocache.authorForYou(activeAccountName)
  //   : await leocache.latestFeed;

  // return json({
  //   threadContents: await cache.getThreads(threadIndices.slice(0, 10)),
  //   threadIndices
  // });
};

export default function Index() {
  const { threadContents, threadIndices } = useLoaderData() as ThreadsLoader;
  const [activeAccount, setPushStartArray] = useAppStore(store => [
    store.account.activeAccount,
    store.threads.setPushStartArray
  ]);

  const [loadedThreads, loadMore, pushToStart, _, isLoadingThreads] =
    useThreads(threadContents, threadIndices);

  useEffect(() => {
    setPushStartArray(pushToStart);
  }, []);

  const handlePost = async (
    body: string,
    pollOptions?: object,
    dimensions?: any
  ) => {
    const operation = await postThreadMain(
      activeAccount!.name,
      body,
      pollOptions,
      dimensions
    );
    createFakeThread(
      pushToStart as (thread: PossibleThreadContent) => void,
      operation,
      threadContents[0].thread.index + 1,
      activeAccount!
    );
  };

  return (
    <Fragment>
      {activeAccount !== null && (
        <ThreadsEditor mainThreadEditor={true} handlePost={handlePost} />
      )}
      <ThreadList
        loadedThreads={loadedThreads as PossibleThreadContent[]}
        loadMore={loadMore as () => void}
        isLoadingThreads={isLoadingThreads as boolean}
        // isThreadView={false as boolean}
        // threadIndices={threadIndices}
      />
    </Fragment>
  );
}

interface ThreadListProps {
  loadedThreads: Thread[];
  loadMore: () => void;
  isLoadingThreads: boolean;
  className?: string;
  feedType?: string;
}

export function ThreadList({
  loadedThreads: initialThreads,
  loadMore,
  isLoadingThreads,
  className,
  feedType
}: ThreadListProps) {
  const ref = React.useRef<VirtuosoHandle>(null);

  const cachedSessions = useAppStore(store => store.threads.cachedSession);
  const measurementsCache = useAppStore(
    store => store.threads.measurementsCache
  );
  const setMeasurementsCache = useAppStore(
    store => store.threads.setMeasurementsCache
  );
  const setCachedSession = useAppStore(store => store.threads.setCachedSession);

  const cachedSession = useMemo(() => {
    if (!feedType) return;
    if (!cachedSessions.has(feedType)) return;

    return {
      threads: cachedSessions.get(feedType)?.threads ?? [],
      nextPage: cachedSessions.get(feedType)?.nextPage ?? "0",
      session: cachedSessions.get(feedType)?.session
    };
  }, [cachedSessions, feedType]);

  const loadedThreads = useMemo(() => {
    return cachedSession && cachedSession.threads?.length > 0
      ? cachedSession.threads
      : initialThreads;
  }, [initialThreads, cachedSession]);

  useSaveLeoCacheSeen(feedType, loadedThreads);

  useEffect(() => {
    if (!feedType) return;
    if (cachedSession && cachedSession.threads.length >= loadedThreads.length)
      return;

    setCachedSession(feedType, {
      threads: loadedThreads
    });
  }, [loadedThreads.length, cachedSession]);

  useEffect(() => {
    if (cachedSession && cachedSession.threads.length > 0) return;

    loadMore();
  }, [cachedSession]);

  return (
    <div className="relative overflow-hidden">
      <Virtuoso
        restoreStateFrom={
          feedType ? measurementsCache.get(feedType) ?? undefined : undefined
        }
        context={{ isLoadingThreads }}
        ref={ref}
        useWindowScroll
        className="flex flex-col"
        computeItemKey={(i, item) => item?.permlink || i}
        data={loadedThreads}
        endReached={loadMore}
        increaseViewportBy={{ top: 2400, bottom: 3200 }}
        itemContent={(i, threadContent: Thread) => {
          return (
            <ThreadView
              threadContent={threadContent}
              key={threadContent?.permlink}
              feedType={feedType}
              beforeTransition={() => {
                if (!ref.current || !feedType) return;

                ref.current.getState(snapshot =>
                  setMeasurementsCache(feedType, snapshot)
                );
              }}
            />
          );
        }}
        components={{ Footer }}
      />
    </div>
  );
}

export const ThreadListV2 = React.memo(
  ({
    activeAccount,
    feedType
  }: {
    activeAccount: string | null | undefined;
    feedType: string;
  }) => {
    const ref = React.useRef<VirtuosoHandle>(null);

    const cachedSessions = useAppStore(store => store.threads.cachedSession);
    const measurementsCache = useAppStore(
      store => store.threads.measurementsCache
    );
    const setMeasurementsCache = useAppStore(
      store => store.threads.setMeasurementsCache
    );
    const setCachedSession = useAppStore(
      store => store.threads.setCachedSession
    );
    const setPushStartArray = useAppStore(
      store => store.threads.setPushStartArray
    );

    const cachedSession = useMemo(() => {
      if (!cachedSessions.has(feedType)) return null;

      return {
        threads: cachedSessions.get(feedType)?.threads ?? [],
        nextPage: cachedSessions.get(feedType)?.nextPage ?? "0",
        session: cachedSessions.get(feedType)?.session ?? generateFeedSession()
      };
    }, [cachedSessions, feedType]);

    const {
      threads,
      nextPage,
      session,
      fetching,
      error,
      newThreadsWaitToPush,
      fetchMore,
      createFakeThread,
      pushNewThreads,
      dumpThreads
    } = useThreadsV2({
      feed: feedType,
      initialThreads: cachedSession?.threads ?? [],
      initialNextPage: cachedSession?.nextPage ?? "0",
      initialSession: cachedSession?.session ?? generateFeedSession()
    });

    useSaveLeoCacheSeen(feedType, threads);

    const isPersonalFeed = useMemo(
      () => feedType === "foryou" || feedType === "following",
      [feedType]
    );

    useEffect(() => {
      setPushStartArray(createFakeThread);
    }, [createFakeThread, setPushStartArray]);

    useEffect(() => {
      if (cachedSession && cachedSession.threads.length > 0) return;
      if (isPersonalFeed && !activeAccount) return;

      dumpThreads();
      fetchMore({
        feed: feedType,
        page: "0",
        session: generateFeedSession(),
        renew: true,
        ...(isPersonalFeed ? { author: activeAccount } : {})
      });
    }, [feedType, isPersonalFeed, activeAccount, cachedSession, activeAccount]);

    useEffect(() => {
      if (cachedSession && cachedSession.threads.length >= threads.length)
        return;

      setCachedSession(feedType, {
        threads,
        nextPage,
        session
      });
    }, [threads.length]);

    return (
      <div className="relative overflow-hidden">
        <PushNewThreadsIndicator
          feed={feedType}
          newThreadsWaitToPush={newThreadsWaitToPush}
          pushNewThreads={pushNewThreads}
        />

        <Virtuoso
          restoreStateFrom={measurementsCache.get(feedType) ?? undefined}
          context={{ isLoadingThreads: fetching, error }}
          ref={ref}
          useWindowScroll
          className="flex flex-col"
          computeItemKey={(i, item) => item?.permlink || i}
          data={threads}
          endReached={() =>
            fetchMore({
              feed: feedType,
              page: nextPage,
              session,
              ...(isPersonalFeed ? { author: activeAccount } : {})
            })
          }
          increaseViewportBy={{ top: 2400, bottom: 3200 }}
          itemContent={(i, threadContent: Thread) => {
            return (
              <ThreadView
                threadContent={threadContent}
                key={threadContent?.permlink}
                feedType={feedType}
                beforeTransition={() => {
                  if (!ref.current) return;

                  ref.current.getState(snapshot =>
                    setMeasurementsCache(feedType, snapshot)
                  );
                }}
              />
            );
          }}
          components={{ Footer }}
        />
      </div>
    );
  }
);

ThreadListV2.displayName = "ThreadList";

export function AuthorThreadListV2({ author, searchQuery, sort, depth }: any) {
  const cachedSessions = useAppStore(store => store.threads.cachedSession);
  const measurementsCache = useAppStore(
    store => store.threads.measurementsCache
  );
  const setMeasurementsCache = useAppStore(
    store => store.threads.setMeasurementsCache
  );
  const setCachedSession = useAppStore(store => store.threads.setCachedSession);
  const setPushStartArray = useAppStore(
    store => store.threads.setPushStartArray
  );

  const cachedSession = useMemo(() => {
    if (!cachedSessions.has(author)) return;

    return {
      threads: cachedSessions.get(author)?.threads ?? [],
      nextPage: cachedSessions.get(author)?.nextPage ?? "0"
    };
  }, [cachedSessions, author]);

  const {
    threads,
    nextPage,
    fetching,
    error,
    fetchMore,
    dumpThreads,
    createFakeThread
  } = useThreadsV2({
    initialThreads: cachedSession?.threads ?? [],
    initialNextPage: cachedSession?.nextPage ?? "0",
    author,
    sort,
    depth,
    search: searchQuery
  });

  const ref = React.useRef<VirtuosoHandle>(null);

  useEffect(() => {
    setPushStartArray(createFakeThread);
  }, [createFakeThread]);

  useEffect(() => {
    if (cachedSession && cachedSession.threads.length > 0 && !searchQuery)
      return;

    dumpThreads();
    fetchMore({
      page: "0",
      author,
      search: searchQuery,
      sort,
      depth,
      renew: true
    });
  }, [author, searchQuery]);

  useEffect(() => {
    if (cachedSession && cachedSession.threads.length >= threads.length) return;

    setCachedSession(author, {
      threads,
      nextPage
    });
  }, [threads.length]);

  return (
    <div className="relative overflow-hidden">
      <Virtuoso
        restoreStateFrom={measurementsCache.get(author) ?? undefined}
        context={{ isLoadingThreads: fetching, error }}
        ref={ref}
        useWindowScroll
        className="flex flex-col"
        computeItemKey={(i, item) => item?.permlink || i}
        data={threads}
        endReached={() =>
          !searchQuery &&
          fetchMore({
            page: nextPage,
            author,
            search: searchQuery,
            sort,
            depth
          })
        }
        increaseViewportBy={{ top: 2400, bottom: 3200 }}
        itemContent={(i, threadContent: Thread) => {
          return (
            <ThreadView
              threadContent={threadContent}
              key={threadContent?.permlink}
              beforeTransition={() => {
                if (!ref.current) return;

                ref.current.getState(snapshot =>
                  setMeasurementsCache(author, snapshot)
                );
              }}
            />
          );
        }}
        components={{ Footer }}
      />
    </div>
  );
}

interface PushNewThreadsIndicatorProps {
  feed: string;
  newThreadsWaitToPush: Thread[];
  pushNewThreads: () => void;
}

function PushNewThreadsIndicator(props: PushNewThreadsIndicatorProps) {
  const t = loadTranslator(useRouteLoaderData("root").translations)
  const authors: Set<string> = useMemo(() => {
    const _: Set<string> = new Set([]);
    props.newThreadsWaitToPush.forEach((thread: Thread) =>
      _.add(thread.author)
    );

    return _;
  }, [props.newThreadsWaitToPush]);

  function handleLoadNewThreads() {
    if (typeof document === "undefined") return;

    document.documentElement.scrollTo({ top: 0 });
    setTimeout(() => {
      props.pushNewThreads();
    }, 50);
  }

  const visible =
    props.feed === "latest" && props.newThreadsWaitToPush.length > 0;

  // if (props.feed !== "latest") return null;
  // if (props.newThreadsWaitToPush.length === 0) return;

  return (
    <AnimatePresence>
      {visible ? (
        <motion.button
          initial={{ y: -12, x: "-50%", opacity: 0 }}
          animate={{ y: 0, x: "-50%", opacity: 1 }}
          exit={{ y: -12, x: "-50%", opacity: 0 }}
          transition={{ duration: 0.15 }}
          className="fixed top-20 left-1/2 flex items-center justify-center gap-x-1 py-1 pl-1.5 pr-2.5 bg-acc drop-shadow-sm shadow-[0px_2px_12px_2px_rgb(var(--accent)_/_40%)] rounded-full z-1000 hover:brightness-90"
          onClick={() => handleLoadNewThreads()}
        >
          <FontAwesomeIcon
            icon={faArrowUpLong}
            size="sm"
            className="text-pri"
            fixedWidth
          />

          <span className="relative flex items-center justify-center -space-x-3">
            {[...authors].slice(0, 3).map((author, index) => (
              <span
                key={author}
                className="flex border-2 border-acc rounded-full overflow-hidden"
                style={{ zIndex: -index }}
              >
                <SmallAvatar6
                  author={author}
                  className="border-none outline-none"
                  disableThreadcast
                />
              </span>
            ))}
          </span>

          <span className="hidden xs:flex text-pri text-sm font-medium">
            {t("see-new-thread")}
          </span>
        </motion.button>
      ) : null}
    </AnimatePresence>
  );
}

function Footer(props: any) {
  return (
    <div className="flex justify-center items-center w-full">
      {props.context?.isLoadingThreads ? (
        <div className="py-8">
          <Spinner className="fill-pri-d dark:fill-pri" />
        </div>
      ) : props.context?.error ? (
        <div className="flex flex-1 flex-col justify-center items-center py-12 px-6 gap-y-1.5">
          <h1 className="text-2xl font-bold">Something went wrong.</h1>
          <p className="text-sm text-pri/60 dark:text-pri-d/60">
            We couldn't load threads, please refresh and try again.
          </p>
        </div>
      ) : null}
    </div>
  );
}
